/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react"
import PropTypes from "prop-types"
import Helmet from "react-helmet"
import MediaQuery from "react-responsive"
import { Social } from "./Header/Header"
import "./layout.css"

const Layout = ({ children }) => {
  return (
    <section className="Layout">
      <Helmet>
        <meta
          name="viewport"
          content="width=device-width, initial-scale=1.0, viewport-fit=cover"
        />
        <link
          href="https://fonts.googleapis.com/css?family=Permanent+Marker&display=swap"
          rel="stylesheet"
        />
        <link
          href="https://fonts.googleapis.com/css?family=Noto+Sans&display=swap"
          rel="stylesheet"
        />
        <link
          href="https://fonts.googleapis.com/icon?family=Material+Icons"
          rel="stylesheet"
        />
        <link
          rel="stylesheet"
          href="https://unpkg.com/material-components-web@latest/dist/material-components-web.min.css"
        />
      </Helmet>
      {children}
      <footer>
        <MediaQuery maxWidth={1100}>
          <Social size={"3em"} />
        </MediaQuery>
        <p>
          {`A West 117 \u00D7 GhostApps collaboration.`}
          <br />
          Will code for beer.
        </p>
        <p>{`Copyright © ${new Date().getFullYear()} Growlers and Cans Ltd.`}</p>
      </footer>
    </section>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
