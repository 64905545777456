import "./Header.css"
import React, { useState } from "react"
import { Link } from "gatsby"
import { FaFacebook, FaInstagram, FaTwitter, FaUntappd } from "react-icons/fa"
import { IconContext } from "react-icons"
import Sidebar from "./Sidebar"
import MediaQuery from "react-responsive"
import Logo from "../media/Logo"

const Header = () => {
  const [show, setShow] = useState(false)

  return (
    <>
      <MediaQuery minWidth={1200}>
        <header className="Header">
          <Logo />
          <Nav />
          <Social />
        </header>
      </MediaQuery>
      <MediaQuery maxWidth={1199}>
        <header className="Header-mobile">
          <Logo />
          <Burger onClick={() => setShow(p => !p)} isActive={show} />
        </header>
        <Sidebar show={show} onClose={() => setShow(p => !p)} key="sidebar" />
      </MediaQuery>
    </>
  )
}

const Burger = ({ onClick, isActive }) => (
  <button
    className={`hamburger hamburger--squeeze ${isActive ? "is-active" : ""}`}
    type="button"
    onClick={onClick}
  >
    <span className="hamburger-box">
      <span className="hamburger-inner"></span>
    </span>
  </button>
)

const Nav = () => {
  return (
    <nav>
      <Link activeClassName="active" to={"/"}>
        Home
      </Link>
      <Link activeClassName="active" to={"/about"}>
        About Us
      </Link>

      <Link activeClassName="active" to={"/events"}>
        Events
      </Link>
      <Link activeClassName="active" to={"/beers"}>
        Our Beers
      </Link>
      <Link activeClassName="active" to={"/kegs"}>
        Kegs
      </Link>
    </nav>
  )
}

export const Social = ({ size = "2em" }) => {
  return (
    <section className="Social">
      <IconContext.Provider value={{ size }}>
        <a
          className="clickable"
          href="https://www.facebook.com/growlersandcans/"
        >
          <FaFacebook />
        </a>
        <a
          className="clickable"
          href="https://www.instagram.com/growlersandcans/"
        >
          <FaInstagram />
        </a>
        <a
          className="clickable"
          href="https://www.twitter.com/growlersandcans/"
        >
          <FaTwitter />
        </a>
        <a
          className="clickable"
          href="https://untappd.com/v/growlers-and-cans/8652127"
        >
          <FaUntappd />
        </a>
      </IconContext.Provider>
    </section>
  )
}

export default Header
