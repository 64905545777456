import React from "react"
import { useTransition, animated } from "react-spring"

import "./Sidebar.css"
import { Link } from "gatsby"
import { Social } from "./Header"

const Sidebar = ({ show, onClose }) => {
  const transition = useTransition(show, null, {
    from: {
      transform: "translateY(-100%)",
    },
    enter: {
      transform: "translateY(0)",
    },
    leave: {
      transform: "translateX(-100%)",
    },
    unique: true,
  })

  return transition.map(({ item, key, props }) =>
    item ? (
      <animated.div key={key} style={props} className="Sidebar">
        <SidebarContent onClose={onClose} />
      </animated.div>
    ) : null
  )
}

const SidebarContent = ({ onClose }) => {
  return (
    <div className="SidebarContent" onBlur={onClose} role="menu">
      {/* <header /> */}
      <nav>
        <Link onClick={onClose} activeClassName="" to={"/"}>
          Home
        </Link>
        <Link onClick={onClose} activeClassName="" to={"/about"}>
          About Us
        </Link>
        <Link onClick={onClose} activeClassName="" to={"/events"}>
          Events
        </Link>
        <Link onClick={onClose} activeClassName="" to={"/beers"}>
          Our Beers
        </Link>
        <Link onClick={onClose} activeClassName="" to={"/kegs"}>
          Kegs
        </Link>
      </nav>
      <Social size="1.5em" />
    </div>
  )
}

export default Sidebar
